import { Outlet, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

type LayoutProps = {
  children?: React.ReactNode;
};

const cookies = new Cookies(null, { path: '/' });

export const UnloggedLayout = ({ children }: LayoutProps) => {
  cookies.remove('userId');

  return (
    <div
      style={{ backgroundImage: 'url(/assets/bg_gradient.png)' }}
      className="min-h-screen flex flex-col justify-between bg-gray-100"
    >
      <div className="flex flex-col items-center mt-4">
        <a href="/">
          <img
            src="/assets/logo.png"
            alt="Fluents"
            className="w-60 mt-16 mb-4"
          />
        </a>
      </div>

      <div className="flex-grow flex items-center justify-center">
        {children || <Outlet />}
      </div>

      <footer className="bg-white py-4 w-full mt-6">
        <div className="flex justify-center space-x-4">
          <Link to="/login" className="text-gray-600 hover:text-gray-800">
            Login
          </Link>

          <a
            href="https://www.fluents.ai"
            className="text-gray-600 hover:text-gray-800"
            target="_blank"
            rel="noreferrer"
          >
            About
          </a>

          <a
            href="https://www.fluents.ai/contact-us"
            className="text-gray-600 hover:text-gray-800"
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>

          <a
            href="https://storage.googleapis.com/fluents-docs/Fluents%20-%20TOS.pdf"
            className="text-gray-600 hover:text-gray-800"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>

          <a
            href="https://www.fluents.ai/privacy-policy"
            className="text-gray-600 hover:text-gray-800"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </footer>
    </div>
  );
};
