interface UnloggedCardProps {
  children: React.ReactNode;
}

export const UnloggedCard = ({ children }: UnloggedCardProps) => {
  return (
    <div className="w-[95%] max-w-md bg-white p-8 rounded-lg shadow-md">
      {children}
    </div>
  );
};
