import { useLocalStorageState } from 'ahooks';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Sidebar } from './Sidebar';
import { useFirebaseAuth } from 'src/contexts/AuthContext/FirebaseAuthContext';
import RightHandSidebar from './RightHandSidebar';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';

type LayoutProps = {
  children?: React.ReactNode;
};

const cookies = new Cookies(null, { path: '/' });

export const LoggedLayout = ({ children }: LayoutProps) => {
  const auth = useFirebaseAuth();
  const [isCollapsed] = useLocalStorageState<boolean>(
    '@control-plane/sidebar-collapsed',
    {
      defaultValue: false,
      listenStorageChange: true,
    },
  );
  const { isOpen: isRightSidebarOpen } = useRightHandSidebar();
  cookies.set('userId', auth?.user?.uid);

  useEffect(() => {
    const user = auth?.user;
    if (user?.uid) {
      window.pylon = {
        chat_settings: {
          app_id: process.env.REACT_APP_PYLON_APP_ID,
          email: user.email,
          name: user.displayName,
          avatar_url: user.photoURL,
        },
      };
    }
  }, [auth?.user]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-1">
        <Sidebar />

        <div
          className={clsx(
            'transition-all duration-300',
            isCollapsed ? 'ml-20' : 'ml-64',
            isRightSidebarOpen ? 'mr-80 w-full' : 'mr-0 w-full',
          )}
        >
          <div className="bg-primary text-white text-center p-2">
            This is an alpha version of Control Plane. Please report{' '}
            <a href="mailto:support@fluents.ai" className="underline">
              here (support@fluents.ai)
            </a>{' '}
            any issues you find.
          </div>
          <div>{children || <Outlet />}</div>
        </div>

        <RightHandSidebar />
      </div>
    </div>
  );
};
