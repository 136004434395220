import { useLocalStorageState } from 'ahooks';
import { getAuth } from 'firebase/auth';
import React, { createContext, useContext, useState } from 'react';
import { get } from 'src/api/requests';
import { EnvironmentSwitcherModal } from 'src/components/EnvironmentSwitcher';
import { Environment } from 'src/interfaces/environment.interface';

type EnvironmentContext = {
  id: string;
  envId: string;
};

type EnvironmentContextType = {
  environment: EnvironmentContext | undefined;
  updateEnvironment: (environment: EnvironmentContext) => void;
  environmentList: Environment[];
  setEnvironmentList: (environments: Environment[]) => void;
  fetchEnvironments: (page?: number, perPage?: number) => Promise<void>;
};

type EnvironmentProviderProps = {
  children: React.ReactNode;
};

const EnvironmentContext = createContext<EnvironmentContextType>(
  {} as EnvironmentContextType,
);

export const EnvironmentProvider = ({ children }: EnvironmentProviderProps) => {
  const [environmentList, setEnvironmentList] = useState<Environment[]>([]);
  const [environment, setEnvironment] =
    useLocalStorageState<EnvironmentContext>('@control-plane/environment', {
      defaultValue: {} as EnvironmentContext,
      listenStorageChange: true,
    });

  const updateEnvironment = (newEnvironment: EnvironmentContext) => {
    setEnvironment(newEnvironment);
  };

  const fetchEnvironments = async (page: number = 0, perPage: number = 10) => {
    const query = new URLSearchParams({
      page: String(page),
      size: String(perPage),
    });

    const data = await get(`/environments?${query.toString()}`, {
      envId: environment?.envId,
    });

    setEnvironmentList(data);
  };

  const auth = getAuth();

  if (!environment?.envId && !auth.currentUser) {
    auth.signOut();
  }

  if (!environment?.envId && auth.currentUser) {
    return (
      <EnvironmentContext.Provider
        value={{
          environment,
          updateEnvironment,
          environmentList,
          setEnvironmentList,
          fetchEnvironments,
        }}
      >
        <EnvironmentSwitcherModal title="Select environment" modalOpen={true} />
      </EnvironmentContext.Provider>
    );
  }

  return (
    <EnvironmentContext.Provider
      value={{
        environment,
        updateEnvironment,
        environmentList,
        setEnvironmentList,
        fetchEnvironments,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => {
  return useContext(EnvironmentContext);
};
