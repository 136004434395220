export const AgentStub = {
  language: 'en',
  initial_message_delay: 0,
  interrupt_sensitivity: 'low',
  idle_time_seconds: 5,
  endpointing_sensitivity: 'auto',
  conversation_speed: 1,
  llm_fallback: {
    provider: 'openai',
    model_name: 'GPT-3.5-turbo',
  },
  llm_temperature: 0,
};
