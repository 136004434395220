import { formatDuration, intervalToDuration } from 'date-fns';
import TimeAgo from 'javascript-time-ago';
import { useEffect, useState } from 'react';
import { SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { getFirstAndLastFourUUID, processPhoneNumber } from 'src/utils/number';
import { get } from '../api/requests';
import { Heading } from 'src/components/Heading';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { Tooltip } from 'src/components/Tooltip';
import { Button } from 'src/components/Button';
import { ArrowSquareOut, MagnifyingGlass } from '@phosphor-icons/react';
import { CallDetails } from 'src/components/CallDetails';
import { Call } from 'src/interfaces/call.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';

export const CallStatus: { [key: string]: string } = {
  not_started: 'Not started',
  in_progress: 'In progress',
  error: 'Error',
  ended: 'Ended',
};

const headers = [
  { key: 'agent', label: 'Agent', width: '10%' },
  { key: 'error_message', label: 'Event', width: '15%' },
  { key: 'recording_available', label: 'Recording', width: '15%' },
  { key: 'start_time', label: 'Time', width: '15%' },
  { key: 'end_time', label: 'Duration', width: '15%' },
  { key: 'from_number', label: 'From', width: '15%' },
  { key: 'to_number', label: 'To', width: '15%' },
  { key: 'status', label: 'Status', width: '15%' },
  { key: 'actions', label: 'Actions', width: '10%', disableSorting: true },
];

export const Calls = () => {
  const { environment } = useEnvironment();
  const { openSidebar } = useRightHandSidebar();
  const envId = environment?.envId;

  const timeAgo = new TimeAgo('en-US');
  const [agents, setAgents] = useState([]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [filters, setFilters] = useState({
    agent: 'All Agents',
    eventType: 'All Events',
    status: 'All Statuses',
    timePeriod: 'All Time',
  });

  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const updateFilters = (newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, ...newFilters };
      setCurrentPage(1);
      return updatedFilters;
    });
  };

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const filtersCopy: Partial<
      typeof filters & { do_not_call_result: boolean }
    > = {
      ...filters,
    };

    if (filtersCopy.agent === 'All Agents') {
      delete filtersCopy.agent;
    }

    if (filtersCopy.eventType === 'All Events') {
      delete filtersCopy.eventType;
    }

    if (filtersCopy.status === 'All Statuses') {
      delete filtersCopy.status;
    }

    if (filtersCopy.timePeriod === 'All Time') {
      delete filtersCopy.timePeriod;
    }

    if (filtersCopy.eventType === 'Error') {
      filtersCopy.do_not_call_result = true;
      delete filtersCopy.eventType;
    }

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      filters: JSON.stringify(filtersCopy),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/calls?${query.toString()}`, {
      envId,
    });

    const calls = data.items.map((call: Call) => {
      const startDate = call.start_time
        ? new Date(Number(call.start_time) * 1000)
        : null;
      const endDate = call.end_time
        ? new Date(Number(call.end_time) * 1000)
        : null;

      return {
        ...call,
        agent: getFirstAndLastFourUUID(call.agent),
        error_message: call.error_message
          ? 'Error: ' + call.error_message
          : 'Call',
        recording_available: call.recording_available ? 'Available' : '-',
        start_time: startDate ? timeAgo.format(startDate) : '-',
        end_time:
          startDate && endDate
            ? formatDuration(
                intervalToDuration({
                  start: startDate,
                  end: endDate,
                }),
                { delimiter: ', ' },
              )
            : '-',
        from_number: processPhoneNumber(call.from_number),
        to_number: processPhoneNumber(call.to_number),
        status: CallStatus[call.status],
        actions: (
          <Tooltip content="Show call details" position="top">
            <Button variant="outlined" onClick={() => handleCallDetails(call)}>
              <MagnifyingGlass className="cursor-pointer inline-block w-5 h-5" />
            </Button>
          </Tooltip>
        ),
      };
    });

    setRows(calls as never[]);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, filters, sortConfig]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const filterByEventType = (eventType: 'All Events' | 'Error' | 'Call') => {
    updateFilters({ eventType });
  };

  const filterByTimePeriod = (
    timePeriod: 'Last 24 Hours' | '7 Days' | '1 Month' | 'All Time',
  ) => {
    updateFilters({ timePeriod });
  };

  const filterByAgent = (agent: string) => {
    updateFilters({ agent });
  };

  const filterByStatus = (status: string) => {
    const statusKey = Object.keys(CallStatus).find(
      (key) => CallStatus[key] === status,
    );
    updateFilters({ status: statusKey ?? 'All Statuses' });
  };

  const fetchAgents = async () => {
    const data = await get(`/agents`, {
      envId,
    });

    setAgents((prevAgents) => [
      ...(prevAgents as never[]),
      ...(data.items as never[]),
    ]);
  };

  const handleCallDetails = (call: Call) => {
    openSidebar(<CallDetails call={call} />, 'Call Details');
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <div className="flex-1">
      <Heading
        title="Calls"
        subtitle="See the calls made using your agents here"
      >
        <div className="flex">
          <div className="w-fit">
            <SelectBox
              options={[
                { value: 'Last 24 Hours' },
                { value: '7 Days' },
                { value: '1 Month' },
                { value: 'All Time' },
              ]}
              defaultValue={{ value: 'All Time' }}
              variant="contained"
              color="primary"
              size="medium"
              onChange={(value) =>
                filterByTimePeriod(
                  value as 'Last 24 Hours' | '7 Days' | '1 Month' | 'All Time',
                )
              }
              className="w-40 mt-6"
            />
          </div>

          <Button
            className="ml-4 mt-6 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/calls"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-control-plane-400 mb-4">
            Filter by:
          </h2>

          <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
            <SelectBox
              options={[
                { value: 'All Events' },
                { value: 'Error' },
                { value: 'Call' },
              ]}
              defaultValue={{ value: 'All Events' }}
              variant="contained"
              color="primary"
              size="medium"
              onChange={(value) =>
                filterByEventType(value as 'All Events' | 'Error' | 'Call')
              }
              className="col-span-2"
            />

            <SelectBox
              options={[
                { value: 'All Agents' },
                ...agents.map((agent: { id: string }) => ({
                  value: agent.id,
                  label: getFirstAndLastFourUUID(agent.id),
                })),
              ]}
              defaultValue={{ value: 'All Agents' }}
              variant="contained"
              color="primary"
              size="medium"
              onChange={(value) => filterByAgent(value)}
              className="col-span-2"
            />

            <SelectBox
              options={[
                { value: 'All Statuses' },
                { value: 'Not started' },
                { value: 'In progress' },
                { value: 'Error' },
                { value: 'Ended' },
              ]}
              defaultValue={{ value: 'All Statuses' }}
              variant="contained"
              color="primary"
              size="medium"
              className="col-span-2"
              onChange={(value) => filterByStatus(value)}
            />
          </div>
        </div>

        <Table
          headers={headers}
          rows={rows}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={setSize}
          defaultSize={size}
        />
      </ContentWrapper>
    </div>
  );
};
