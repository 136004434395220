import {
  ArrowSquareOut,
  Code,
  Copy,
  MagnifyingGlass,
  Pencil,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { get, patch, post } from 'src/api/requests';
import { VOICE_NAMES } from 'src/constants';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Heading } from 'src/components/Heading';
import { Modal } from 'src/components/Modal';
import { SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Voice, VoicePayload } from 'src/interfaces/voice.interface';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { QueryObject } from 'src/interfaces/queryObject.interface';

const voices = [
  { label: 'Azure', value: 'voice_azure' },
  { label: 'Eleven Labs', value: 'voice_eleven_labs' },
  { label: 'Play HT', value: 'voice_play_ht' },
  { label: 'Rime', value: 'voice_rime' },
];

const newVoice: VoicePayload = {
  type: 'voice_azure',
  voice_name: VOICE_NAMES['voice_azure'][0]?.value,
};

const headers = [
  { key: 'type', label: 'Provider', width: '30%' },
  { key: 'voice_name', label: 'Voice/Model', width: '50%' },
  { key: 'actions', label: 'Action', width: '20%', disableSorting: true },
];

const convertToDto = (form: VoicePayload) => {
  switch (form.type) {
    case 'voice_azure':
      return { type: form.type, voice_name: form.voice_name };
    case 'voice_eleven_labs':
      return { type: form.type, voice_id: form.voice_name };
    case 'voice_play_ht':
      return { type: form.type, voice_id: form.voice_name };
    case 'voice_rime':
      return { type: form.type, speaker: form.voice_name };
    default:
      return form;
  }
};

export const ListVoices = () => {
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [rows, setRows] = useState<Voice[]>([]);
  const [originalRows, setOriginalRows] = useState<Voice[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] = useState<VoicePayload>(newVoice);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);
    closeSidebar();

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/voices?${query.toString()}`, {
      envId,
    });

    const items = data.items;

    setRows(items);
    setOriginalRows(items);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, sortConfig]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const resetForm = () => {
    setForm(newVoice);
  };

  const openModalNewVoice = () => {
    setModalOpen(true);
    setSelectedId('');
    resetForm();
  };

  const openModalEditVoice = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
    const voice = rows.find((item) => item.id === id);

    if (voice) {
      setForm({
        type: voice.type,
        voice_name: voice.voice_name || voice.voice_id || voice.speaker,
      });
    }
  };

  const closeModal = () => {
    closeSidebar();
    setModalOpen(false);
    setSelectedId('');
    resetForm();
  };

  const submitModal = async () => {
    setLoading(true);

    if (selectedId) {
      await patch(`/voices/${selectedId}`, convertToDto(form), {
        envId,
      });
      notification.success('Voice updated successfully');
    } else {
      await post('/voices', convertToDto(form), {
        envId,
      });
      notification.success('Voice created successfully');
    }

    fetchData(currentPage, size);

    setLoading(false);

    closeModal();
  };

  const submitFilter = () => {
    const filteredRows = originalRows.filter((row: Voice) =>
      row.voice_name?.toLowerCase().includes(filter.toLowerCase()),
    );

    setRows(filteredRows);
  };

  const handleEndpointVoice = () => {
    if (selectedId) {
      return `voices/update?id=${selectedId}`;
    } else {
      return 'voices/create';
    }
  };

  const handleAgentEquivalentCode = () => {
    openSidebar(
      <EquivalentCode
        payload={form}
        endpoint={handleEndpointVoice()}
        method="POST"
      />,
      'Equivalent Code',
    );
  };

  useEffect(() => {
    submitFilter();
  }, [debouncedFilter]);

  useEffect(() => {
    if (isOpen && !loading) {
      handleAgentEquivalentCode();
    }
  }, [form, isOpen]);

  return (
    <div className="flex-1">
      <Heading
        title="Voices"
        subtitle="Voices add personality to your conversation by making it feel more human."
      >
        <div className="flex mt-6">
          <Button className="w-40" onClick={openModalNewVoice}>
            Create Voice
          </Button>

          <Button
            className="ml-4 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/voices"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            required
            placeholder="Search voices"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={submitFilter}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={
            rows.map((item: Voice) => ({
              ...item,
              voice_name: VOICE_NAMES[item.type].find(
                (voice) =>
                  voice.value ===
                  (item.voice_name || item.voice_id || item.speaker),
              )?.label,
              actions: (
                <>
                  <Tooltip content="Copy ID to clipboard" position="top">
                    <Button
                      variant="outlined"
                      onClick={() =>
                        copyToClipboard(
                          item.id,
                          notification,
                          'ID Copied to clipboard',
                        )
                      }
                    >
                      <Copy className="cursor-pointer inline-block w-5 h-5" />
                    </Button>
                  </Tooltip>

                  <Button
                    className="ml-2"
                    onClick={() => openModalEditVoice(item.id as string)}
                  >
                    <Pencil className="cursor-pointer inline-block w-5 h-5" />
                  </Button>
                </>
              ),
            })) as never[]
          }
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={setSize}
          defaultSize={size}
        />
      </ContentWrapper>

      <Modal
        title={`${selectedId ? 'Edit' : 'New'} Voice`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button color="default" onClick={submitModal} disabled={loading}>
            {selectedId ? 'Save Voice' : 'Create Voice'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              isOpen ? closeSidebar() : handleAgentEquivalentCode()
            }
            className="flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="provider"
            >
              Provider
            </label>

            <SelectBox
              key={`voice_type_${selectedId}`}
              options={voices}
              defaultValue={voices.find((v) => v.value === form.type)}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) =>
                setForm({
                  ...form,
                  type: value,
                  voice_name: VOICE_NAMES[value][0].value,
                })
              }
              className="min-w-[140px]"
              disabled={loading}
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="voice-name"
            >
              Voice Name
            </label>

            <SelectBox
              key={`voice_name_${selectedId}`}
              options={VOICE_NAMES[form.type]}
              defaultValue={VOICE_NAMES[form.type].find(
                (voice) => voice.value === form.voice_name,
              )}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) => setForm({ ...form, voice_name: value })}
              className="min-w-[140px]"
              disabled={loading}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
