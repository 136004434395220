import React, { createContext, useContext, useState } from 'react';

type RightHandSidebarContextProps = {
  isOpen: boolean;
  openSidebar: (data: JSX.Element, title?: string) => void;
  closeSidebar: () => void;
  title: string | null;
  content: JSX.Element | null;
};

const RightHandSidebarContext = createContext<RightHandSidebarContextProps>(
  {} as RightHandSidebarContextProps,
);

type RightHandSidebarProviderProps = {
  children: JSX.Element;
};

export function RightHandSidebarProvider({
  children,
}: RightHandSidebarProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [title, setTitle] = useState<string | null>('');

  const openSidebar = (data: JSX.Element, title: string = '') => {
    setContent(data);
    setIsOpen(true);
    setTitle(title);
  };

  const closeSidebar = () => {
    setIsOpen(false);
    setContent(null);
  };

  return (
    <RightHandSidebarContext.Provider
      value={{ isOpen, openSidebar, closeSidebar, title, content }}
    >
      {children}
    </RightHandSidebarContext.Provider>
  );
}

export function useRightHandSidebar() {
  return useContext(RightHandSidebarContext);
}
