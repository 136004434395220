import { parsePhoneNumber } from 'libphonenumber-js';

export const processPhoneNumber = (number: string) => {
  const phoneNumber = parsePhoneNumber(
    `${number.includes('+') ? '' : '+'}${number}`,
  );
  return phoneNumber?.formatInternational() ?? '';
};

export const getFirstAndLastFourUUID = (uuid: string) => {
  if (!uuid) return '';
  return `${uuid.slice(0, 4)}....${uuid.slice(-4)}`;
};
