import { X } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';

export default function RightHandSidebar() {
  const location = useLocation();
  const { isOpen, closeSidebar, content, title } = useRightHandSidebar();

  useEffect(() => {
    closeSidebar();
  }, [location.pathname]);

  return (
    <div
      className={`fixed top-0 right-0 h-full w-80 bg-white shadow-lg transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-transform duration-300 ease-in-out z-50`}
    >
      <div
        className={clsx(
          'bg-primary text-white',
          'flex items-center p-2 border-b',
          title
            ? 'justify-between border-gray-200'
            : 'justify-end border-transparent h-10',
        )}
      >
        {title && <h2 className="font-semibold">{title}</h2>}

        <button
          onClick={closeSidebar}
          className={clsx(
            title
              ? 'text-white hover:text-gray-400'
              : 'text-gray-500 hover:text-gray-700',
            'focus:outline-none',
          )}
        >
          <X />
        </button>
      </div>

      <div className={clsx('p-4', !title && 'pt-0', 'overflow-auto h-full')}>
        {content ? <>{content}</> : <p>No data available.</p>}
      </div>
    </div>
  );
}
