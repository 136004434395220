import { UnloggedCard } from 'src/components/UnloggedCard';

export const SamplePageUnlogged = () => {
  return (
    <UnloggedCard>
      <h2 className="text-2xl font-bold mb-6 text-center">
        Sample Page Unlogged
      </h2>

      <div className="flex-1 bg-gray-100 p-6">
        <p>Just a sample page for unlogged users.</p>
      </div>
    </UnloggedCard>
  );
};
