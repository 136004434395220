import { AgentStepProps } from '../../../interfaces/agent.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { limitString } from 'src/utils/limitString';
import { SelectBox } from 'src/components/SelectBox';
import { Link } from 'react-router-dom';
import { VOICE_NAMES } from 'src/constants';
import { ReactNode, useEffect, useState } from 'react';
import { AGENT_TEMPLATES } from '../constants';
import { Code, Handshake, Headset, Money } from '@phosphor-icons/react';
import { Button } from 'src/components/Button';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { EquivalentCode } from 'src/components/EquivalentCode';

const TEMPLATE_ICONS: { [key: string]: ReactNode } = {
  customer_support: <Headset />,
  debt_relief: <Money />,
  sales: <Handshake />,
};

export const AgentSingleStep = ({
  agent,
  setAgent,
  loading = false,
  prompts,
  voices,
}: AgentStepProps & { prompts: Prompt[]; voices: Voice[] }) => {
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const currentVoice = voices.find((voice) => voice.id === agent.voice) || {
    id: undefined,
    voice_name: '',
  };
  const currentPrompt = prompts.find((prompt) => prompt.id === agent.prompt);
  const [promptContent, setPromptContent] = useState('');

  const promptList = prompts.map((prompt) => ({
    value: prompt.id,
    label: limitString(prompt.content, 50),
  }));

  const handlePromptEdit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromptContent(event.target.value);
  };

  const endpoint = agent.id ? `agents/update?id=${agent.id}` : 'agents/create';

  const handleAgentEquivalentCode = () => {
    openSidebar(
      <EquivalentCode payload={agent} endpoint={endpoint} method="POST" />,
      'Equivalent Code',
    );
  };

  useEffect(() => {
    if (typeof agent?.prompt === 'string') {
      setPromptContent(
        prompts.find((prompt) => prompt.id === agent.prompt)?.content || '',
      );
    }
  }, [agent?.prompt, prompts]);

  useEffect(() => {
    if (promptContent !== currentPrompt?.content) {
      setAgent({ ...agent, prompt: { content: promptContent } });
    }
  }, [promptContent]);

  useEffect(() => {
    if (isOpen && !loading) {
      handleAgentEquivalentCode();
    }
  }, [agent, isOpen]);

  return (
    <div>
      <div className="flex justify-end mb-1">
        <Button
          onClick={() =>
            isOpen ? closeSidebar() : handleAgentEquivalentCode()
          }
          className="flex items-center justify-center"
        >
          <Code className="mr-2" size={20} />
          Show equivalent code
        </Button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <div className="mb-3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="agent-name"
            >
              Agent Name
            </label>

            <input
              type="text"
              id="agent-name"
              placeholder="Enter agent name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
              value={agent.name}
              onChange={(e) => setAgent({ ...agent, name: e.target.value })}
              disabled={loading}
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="initialMessage"
          >
            Initial Message
          </label>

          <input
            type="text"
            id="initialMessage"
            placeholder="Hi {name}, this is Fluents AI. How can I help you today?"
            value={agent.initial_message}
            onChange={(e) =>
              setAgent({ ...agent, initial_message: e.target.value })
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
          />
        </div>

        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="voice"
          >
            Voice
          </label>

          {voices.length === 0 && (
            <span className="text-gray-700 text-sm font-bold">
              No voices available, pick one on the voices page. Click{' '}
              <Link to="/voices">here</Link>
            </span>
          )}

          {voices.length > 0 && (
            <SelectBox
              options={voices.map((voice) => ({
                value: String(voice.id),
                label: VOICE_NAMES[voice.type].find(
                  (elm) =>
                    elm.value ===
                    (voice.voice_name || voice.voice_id || voice.speaker),
                )?.label,
              }))}
              defaultValue={
                currentVoice.id
                  ? {
                      value: currentVoice.id,
                      label: currentVoice.voice_name,
                    }
                  : undefined
              }
              onChange={(value) => setAgent({ ...agent, voice: value })}
              className="min-w-[140px]"
            />
          )}
        </div>

        <div className="mb-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="prompt"
          >
            Prompt Library
          </label>

          {promptList.length === 0 && (
            <span className="text-gray-700 text-sm font-bold">
              No prompts available, try creating one on the prompts page. Click{' '}
              <Link to="/prompts">here</Link>
            </span>
          )}

          {promptList.length > 0 && (
            <SelectBox
              options={promptList}
              defaultValue={promptList.find(
                (prompt) => agent.prompt === prompt.value,
              )}
              onChange={(value) => {
                setAgent({ ...agent, prompt: value });
              }}
              className="min-w-[140px]"
              disabled={loading}
            />
          )}
        </div>

        <div className="mb-3 col-span-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Prompt Templates
          </label>
          <div className="flex space-x-6">
            {Object.keys(AGENT_TEMPLATES).map((key) => {
              const label = key
                .split('_')
                .map(
                  (labelPart) =>
                    `${labelPart[0].toUpperCase()}${labelPart.substring(1)}`,
                )
                .join(' ');
              return (
                <Button
                  key={key}
                  color="secondary"
                  className="flex flex-col items-center w-48"
                  onClick={() => setPromptContent(AGENT_TEMPLATES[key])}
                >
                  {TEMPLATE_ICONS[key]}
                  <div className="mt-2">{label}</div>
                </Button>
              );
            })}
          </div>
        </div>

        <div className="mb-6 col-span-2">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="text"
          >
            Prompt
          </label>
          <textarea
            id="text"
            placeholder="Prompt text"
            value={promptContent}
            onChange={handlePromptEdit}
            className="w-full h-40 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400 md:h-80"
          />
        </div>
      </div>
    </div>
  );
};
