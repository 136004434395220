import { User, Users, UsersThree, Building, Confetti } from '@phosphor-icons/react';
import { ReactNode, useEffect, useState } from 'react';
import Stripe from 'stripe';
import { get, patch, post } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Heading } from 'src/components/Heading';
import { PricingCard } from 'src/components/PricingCard';

const planImgs: {[key: string]: ReactNode} = {
  "developer": <User size={24} />,
  "team": <Users size={24} />,
  "business": <UsersThree size={24} />,
}

const FREE_PLAN_NAME = "Developer"

type Plan = {
  billingPriceId: string;
  price: string;
  name: string;
  features: string[];
}

export const Billing = () => {
  const key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  if (!key) {
    throw 'No Stripe key configured. Check environment variables.';
  }

  const [currentSubscription, setCurrentSubscription] =
    useState<Stripe.Subscription>();
  const [currentPlan, setCurrentPlan] = useState<Stripe.Plan>();
  const [plans, setPlans] = useState<Plan[]>([]);

  const redirectCheckout = async (priceOption: string) => {
    const results = await post('/stripe/checkout-session', {
      priceOption,
    });
    window.location.href = results;
  };

  const redirectBillingPortalUrl = async () => {
    const results = await post('/stripe/customer-portal-session', {});
    window.location.href = results;
  };

  const updateSubscription = async (priceId: string) => {
    await patch('/stripe/subscription', { priceOption: priceId });
    await fetchSubscriptions();
  };

  const fetchSubscriptions = async () => {
    const results = await get('/stripe/subscription');
    if (!results.length) {
      return;
    }
    setCurrentSubscription(results[0]);
  };

  const fetchBillingPlans = async () => {
    const plans: Plan[] = await get('/plans');
    setPlans(plans)
  };

  useEffect(() => {
    fetchBillingPlans();
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    setCurrentPlan(currentSubscription?.items.data[0].plan);
  }, [currentSubscription]);

  const currentPlanIndex = plans.findIndex(
    (plan) => plan.billingPriceId === currentPlan?.id,
  );

  return (
    <>
      <Heading
        title="Billing"
        subtitle="Choose the option that fits your needs today"
      />
      <ContentWrapper>
        {currentSubscription && (
          <div className="flex items-center">
            <div>
              Current subscription:{' '}
              <span className="font-bold">
                {
                  plans.find(
                    (plan) => plan.billingPriceId === currentPlan?.id,
                  )?.name || "Custom"
                }
              </span>
              {currentSubscription.cancel_at && (
                <span className="font-bold">
                  {` ends on ${new Date(currentSubscription.cancel_at * 1000).toLocaleDateString()}`}
                </span>
              )}
            </div>
            <Button className={'ml-4'} onClick={redirectBillingPortalUrl}>
              Manage existing subscription
            </Button>
          </div>
        )}
        {!currentSubscription && (
          <div className="flex items-center">
            <div>
              Current subscription: <span className="font-bold">{FREE_PLAN_NAME}</span>
            </div>
          </div>
        )}
        <div className="flex w-full">
          <div
            className={`max-w-[1240px] mx-auto grid lg:grid-cols-4 gap-20 align-center`}
          >
            {plans.map((plan, index) => {
              let pricingLabel = '';
              if (currentPlanIndex > -1 && currentPlanIndex < index) {
                pricingLabel = 'Upgrade plan';
              } else if (currentPlanIndex === index) {
                pricingLabel = 'Current plan';
              }
              return (
                <PricingCard
                  key={plan.billingPriceId}
                  onClick={async () => {
                    if (!pricingLabel) {
                      await redirectCheckout(plan.billingPriceId);
                    }
                    await updateSubscription(plan.billingPriceId);
                  }}
                  disabled={!plan.billingPriceId || currentPlan?.id === plan.billingPriceId || (!pricingLabel && !!currentPlan?.id)}
                  buttonLabel={pricingLabel || ''}
                  title={plan.name}
                  price={isNaN(parseInt(plan.price)) ? "Free" : `$${parseInt(plan.price) / 100}`}
                  img={planImgs[plan.name.toLowerCase()] || <Confetti size={24} />}
                  features={plan.features}
                />
              );
            })}
            <PricingCard
              key={'custom'}
              onClick={() => {
                window.location.href =
                  'mailto:support@fluents.ai?subject=Enterprise+Inquiry';
              }}
              buttonLabel={'Contact us'}
              title="Enterprise"
              price="Custom"
              features={['Flexible options']}
              img={<Building size={24} />}
            />
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};
