const firebaseErrorMessages = {
  'auth/email-already-in-use': 'This email is already in use.',
  'auth/invalid-email': 'The email address is invalid.',
  'auth/user-not-found': 'No user found with this email.',
  'auth/wrong-password': 'The password is incorrect.',
  'auth/weak-password': 'The password must be at least 6 characters long.',
  'auth/too-many-requests':
    'Too many unsuccessful login attempts. Please try again later.',
  'auth/network-request-failed': 'Network error. Please check your connection.',
  'auth/requires-recent-login':
    'Please log in again to proceed with this action.',
  'auth/user-disabled': 'This account has been disabled.',
  'auth/operation-not-allowed': 'This operation is not allowed.',
  'auth/missing-email': 'Please enter an email address.',
  'auth/missing-password': 'Please enter a password.',
  'auth/invalid-password': 'The password is invalid.',
  'auth/invalid-verification-code': 'The verification code is invalid.',
  'auth/invalid-credential': 'The credential is invalid.',
};

export const getFirebaseErrorMessage = (errorCode) => {
  return (
    firebaseErrorMessages[errorCode] ||
    'An unexpected error occurred. Please try again.'
  );
};
