import { FirebaseError } from 'firebase/app';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { UnloggedCard } from 'src/components/UnloggedCard';
import { useFirebaseAuth } from 'src/contexts/AuthContext/FirebaseAuthContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { getFirebaseErrorMessage } from 'src/utils/FirebaseErrorUtils';
import { Button } from '../components/Button';

export const ResetPassword = () => {
  const authed = useFirebaseAuth();

  if (authed && !authed.loading && authed.uid) {
    return <Navigate to="/" />;
  }

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const notification = useNotification();
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      notification.success('Password reset email sent!');

      navigate('/login');
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        notification.error(getFirebaseErrorMessage(error.code));
      } else {
        notification.error('Error sending password reset email');
      }
    }

    setLoading(false);
  };

  return (
    <UnloggedCard>
      <h2 className="text-2xl font-bold mb-6 text-center">Reset Password</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <Button className="w-full mb-6" type="submit" disabled={loading}>
          Send Reset Link
        </Button>

        <div className="relative mb-6">
          <div className="flex items-center justify-center">
            <div className="w-full border-t border-gray-300"></div>
            <span className="absolute bg-white px-2 text-gray-600">or</span>
          </div>
        </div>

        <Button
          className="w-full mb-2"
          onClick={() => navigate('/login')}
          disabled={loading}
        >
          Back to Login
        </Button>
      </form>
    </UnloggedCard>
  );
};
