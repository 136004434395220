export const limitString = (
  str: string,
  limit: number,
  disableEllipsis = false,
) => {
  if (str.length <= limit) {
    return str;
  }

  return str.substring(0, limit) + (disableEllipsis ? '' : '...');
};
