import {
  ArrowSquareOut,
  Code,
  Copy,
  MagnifyingGlass,
  Pencil,
  Trash,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { get, patch, post, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Modal } from 'src/components/Modal';
import { Option, SelectBox } from 'src/components/SelectBox';
import Table, { Row } from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { AccountConnection } from 'src/interfaces/accountConnection.interface';
import { Agent } from 'src/interfaces/agent.interface';
import { Number } from 'src/interfaces/number.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { getFirstAndLastFourUUID, processPhoneNumber } from 'src/utils/number';

type ModalType = 'buy' | 'link';

type FormNumber = {
  telephony_provider: string;
  area_code?: string;
  phone_number?: string;
  telephony_account_connection?: string;
  inbound_agent: string;
};

const headers = [
  { key: 'number', label: 'Number', width: '20%' },
  { key: 'inbound_agent', label: 'Agent', width: '20%' },
  { key: 'telephony_provider', label: 'Telephony Provider', width: '20%' },
  {
    key: 'telephony_account_connection',
    label: 'Account Connection',
    width: '20%',
  },
  { key: 'actions', label: 'Action', width: '20%', disableSorting: true },
];

const providers = [
  { value: 'twilio', label: 'Twilio' },
  { value: 'vonage', label: 'Vonage' },
];

export const ListNumbers = () => {
  const { openSidebar, closeSidebar, isOpen } = useRightHandSidebar();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const notification = useNotification();

  const [rows, setRows] = useState<Number[]>([]);
  const [originalRows, setOriginalRows] = useState<Number[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>('buy');
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const [agents, setAgents] = useState<Option[]>([]);
  const [accountConnections, setAccountConnections] = useState<Option[]>([]);

  const [editingNumber, setEditingNumber] = useState<Number | null>(null);
  const [cancelingNumber, setCancelingNumber] = useState<Number | null>(null);

  const [formNumber, setFormNumber] = useState<FormNumber>({
    telephony_provider: providers[0].value,
    area_code: '',
    phone_number: '',
    telephony_account_connection: '',
    inbound_agent: '',
  });

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/numbers?${query.toString()}`, {
      envId,
    });

    const items = data.items.map((item: Number) => ({
      ...item,
      number: processPhoneNumber(item.number),
      inbound_agent: getFirstAndLastFourUUID(item.inbound_agent),
      telephony_account_connection: getFirstAndLastFourUUID(
        item.telephony_account_connection || '',
      ),
    }));

    setRows(items);
    setOriginalRows(items);

    setTotalItems(data.total);
    setLoading(false);
  };

  const fetchAgents = async () => {
    const data = await get('/agents?size=30', {
      envId,
    });

    setAgents(
      data.items.map((agent: Agent) => ({
        value: agent.id || '',
        label: agent.name,
      })),
    );
  };

  const fetchAccountConnections = async () => {
    const data = await get('/account-connections?size=30', {
      envId,
    });

    setAccountConnections(
      data.items.map((accountConnection: AccountConnection) => ({
        value: accountConnection.id || '',
        label: getFirstAndLastFourUUID(accountConnection.id || ''),
      })),
    );
  };

  const fetchRelatedData = async () => {
    await Promise.all([fetchAgents(), fetchAccountConnections()]);
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, sortConfig]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const closeModal = () => {
    setFormNumber({
      telephony_provider: providers[0].value,
      area_code: '',
      phone_number: '',
      telephony_account_connection: '',
      inbound_agent: '',
    });

    setModalOpen(false);
    closeSidebar();
  };

  const handleModalBuy = () => {
    setModalType('buy');
    setModalOpen(true);
  };

  const handleModalImport = () => {
    setModalType('link');
    setModalOpen(true);
  };

  const confirmNumberAction = async () => {
    setLoading(true);
    const url = modalType === 'link' ? '/numbers/link' : '/numbers/buy';

    const clonedFormNumber = { ...formNumber };

    if (modalType === 'buy') {
      delete clonedFormNumber.phone_number;
    } else {
      delete clonedFormNumber.area_code;
    }

    if (
      !clonedFormNumber.telephony_account_connection ||
      clonedFormNumber.telephony_account_connection.length === 0
    ) {
      delete clonedFormNumber.telephony_account_connection;
    }

    await post(url, clonedFormNumber, { envId });
    notification.success(
      `Number ${modalType === 'link' ? 'linked' : 'bought'}`,
    );
    setModalOpen(false);
    fetchData(currentPage, size);
  };

  const submitFilter = () => {
    const filteredRows = originalRows.filter((row: Number) =>
      row.number?.toLowerCase().includes(filter.toLowerCase()),
    );

    setRows(filteredRows);
  };

  const openEditModal = (number: Number) => {
    setEditingNumber(number);
    setModalEditOpen(true);
  };

  const closeEditModal = () => {
    setEditingNumber(null);
    closeSidebar();
    setModalEditOpen(false);
  };

  const cleanPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/[^0-9]/g, '');
  };

  const confirmEditAction = async () => {
    if (editingNumber) {
      setLoading(true);

      await patch(
        `/numbers/${editingNumber.id}?phone_number=${cleanPhoneNumber(editingNumber.number)}`,
        {
          inbound_agent: editingNumber.inbound_agent,
        },
        { envId },
      );

      setLoading(false);

      notification.success('Number updated successfully');
      setModalEditOpen(false);
      fetchData(currentPage, size);
      setEditingNumber(null);
    }
  };

  const openDeleteModal = (number: Number) => {
    setCancelingNumber(number);
    setModalDeleteOpen(true);
  };

  const confirmDeleteAction = async () => {
    if (cancelingNumber) {
      setLoading(true);

      const cleanedNumber = cleanPhoneNumber(cancelingNumber.number);

      await remove(`/numbers/cancel/${cleanedNumber}`, { envId });

      setLoading(false);

      notification.success('Number canceled successfully');
      setModalDeleteOpen(false);
      fetchData(currentPage, size);
      setCancelingNumber(null);
    }
  };

  const closeDeleteModal = () => {
    setModalDeleteOpen(false);
    setCancelingNumber(null);
  };

  useEffect(() => {
    fetchRelatedData();
  }, []);

  useEffect(() => {
    submitFilter();
  }, [debouncedFilter]);

  const endpoint = editingNumber
    ? `numbers/update?phone_number=${cleanPhoneNumber(editingNumber.number)}`
    : modalType === 'link'
      ? `numbers/link`
      : `numbers/buy`;

  const handleProviderEquivalentCode = () => {
    const payloadBuy = {
      ...formNumber,
      phone_number: undefined,
    };

    const payloadLink = {
      ...formNumber,
      area_code: undefined,
    };

    openSidebar(
      <EquivalentCode
        payload={
          editingNumber
            ? {
                inbound_agent: editingNumber.inbound_agent,
              }
            : modalType === 'link'
              ? payloadLink
              : payloadBuy
        }
        endpoint={endpoint}
        method="POST"
      />,
      'Equivalent Code',
    );
  };

  useEffect(() => {
    if (isOpen && !loading) {
      handleProviderEquivalentCode();
    }
  }, [formNumber, isOpen]);

  useEffect(() => {
    if (isOpen && !loading) {
      handleProviderEquivalentCode();
    }
  }, [editingNumber, isOpen]);

  return (
    <div className="flex-1">
      <Heading
        title="Numbers"
        subtitle="Phone numbers available for your account"
      >
        <div className="flex mt-6">
          <Button className="mr-2" onClick={handleModalBuy}>
            Buy
          </Button>

          <Button onClick={handleModalImport}>Import</Button>

          <Button
            className="ml-2 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/numbers"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            required
            placeholder="Search numbers"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={submitFilter}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={rows.map(
            (row) =>
              ({
                number: (
                  <Tooltip
                    content={
                      row.active
                        ? 'Copy Number to clipboard'
                        : 'Number is inactive'
                    }
                    position="top"
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        copyToClipboard(
                          row.number,
                          notification,
                          'Number Copied to clipboard',
                        )
                      }
                      disabled={!row.active}
                    >
                      {row.number}
                    </Button>
                  </Tooltip>
                ),
                inbound_agent: row.inbound_agent,
                telephony_provider: row.telephony_provider,
                telephony_account_connection: row.telephony_account_connection,
                actions: (
                  <>
                    <Tooltip content="Copy ID to clipboard" position="top">
                      <Button
                        variant="outlined"
                        onClick={() =>
                          copyToClipboard(
                            row.id,
                            notification,
                            'ID Copied to clipboard',
                          )
                        }
                      >
                        <Copy className="cursor-pointer inline-block w-5 h-5" />
                      </Button>
                    </Tooltip>

                    <Button className="ml-2" onClick={() => openEditModal(row)}>
                      <Pencil className="cursor-pointer inline-block w-5 h-5" />
                    </Button>

                    {row.active && (
                      <Button
                        className="ml-2"
                        onClick={() => openDeleteModal(row)}
                        color="error"
                      >
                        <Trash className="cursor-pointer inline-block w-5 h-5" />
                      </Button>
                    )}
                  </>
                ),
              }) as Row,
          )}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={setSize}
          defaultSize={size}
        />
      </ContentWrapper>

      {/* Buy/Link Number Modal */}
      <Modal
        title={`${modalType === 'link' ? 'Link' : 'Buy'} Number`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button
            color="default"
            onClick={() => confirmNumberAction()}
            disabled={loading}
          >
            {modalType === 'link' ? 'Link' : 'Buy'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              isOpen ? closeSidebar() : handleProviderEquivalentCode()
            }
            className="flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="telephony_provider"
              >
                Provider
              </label>

              <SelectBox
                options={providers}
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) =>
                  setFormNumber({ ...formNumber, telephony_provider: value })
                }
                className="min-w-[140px]"
                defaultValue={providers[0]}
                disabled={loading}
              />
            </div>

            {modalType === 'buy' && (
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="area-code"
                >
                  Area Code
                </label>

                <input
                  type="number"
                  id="area-code"
                  placeholder="Enter area code"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  onChange={(e) =>
                    setFormNumber({ ...formNumber, area_code: e.target.value })
                  }
                  value={formNumber.area_code}
                  disabled={loading}
                />
              </div>
            )}

            {modalType === 'link' && (
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phone-number"
                >
                  Phone Number
                </label>

                <input
                  type="number"
                  id="phone-number"
                  placeholder="Enter phone number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
                  onChange={(e) =>
                    setFormNumber({
                      ...formNumber,
                      phone_number: e.target.value,
                    })
                  }
                  value={formNumber.phone_number}
                  disabled={loading}
                />
              </div>
            )}
          </div>

          <div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="account-connection"
              >
                Account Connection
              </label>

              <SelectBox
                options={accountConnections}
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) =>
                  setFormNumber({
                    ...formNumber,
                    telephony_account_connection: value,
                  })
                }
                className="min-w-[140px]"
                defaultValue={accountConnections.find(
                  (item) =>
                    item.value === formNumber.telephony_account_connection,
                )}
                disabled={loading}
              />
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="inbound-agent"
              >
                Inbound Agent
              </label>

              <SelectBox
                options={agents}
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) =>
                  setFormNumber({ ...formNumber, inbound_agent: value })
                }
                className="min-w-[140px]"
                defaultValue={agents.find(
                  (item) => item.value === formNumber.inbound_agent,
                )}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* Edit Number Modal */}
      <Modal
        title="Edit Number"
        isOpen={modalEditOpen}
        onClose={() => closeEditModal()}
        actionButton={
          <Button
            color="default"
            onClick={() => confirmEditAction()}
            disabled={loading}
          >
            Save Number
          </Button>
        }
      >
        {editingNumber && (
          <>
            <div className="flex justify-end mb-1">
              <Button
                onClick={() =>
                  isOpen ? closeSidebar() : handleProviderEquivalentCode()
                }
                className="flex items-center justify-center"
              >
                <Code className="mr-2" size={20} />
                Show equivalent code
              </Button>
            </div>

            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Current Number
                </label>
                <input
                  type="text"
                  value={editingNumber.number}
                  disabled
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Select Agent
                </label>
                <SelectBox
                  options={agents}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onChange={(value) =>
                    setEditingNumber({
                      ...editingNumber,
                      inbound_agent: value,
                    })
                  }
                  defaultValue={agents.find(
                    (agent) => agent.value === editingNumber.inbound_agent,
                  )}
                  key={editingNumber.inbound_agent}
                  disabled={loading}
                />
              </div>
            </div>
          </>
        )}
      </Modal>

      {/* Cancel Number Modal */}
      <Modal
        title="Cancel Number"
        isOpen={modalDeleteOpen}
        onClose={closeDeleteModal}
        actionButton={
          <Button
            color="error"
            onClick={() => confirmDeleteAction()}
            disabled={loading}
          >
            Cancel Number
          </Button>
        }
      >
        <p className="text-sm text-gray-700">
          Are you sure you want to cancel this number? (
          {cancelingNumber?.number})
        </p>
      </Modal>
    </div>
  );
};
