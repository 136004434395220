import React from 'react';

type HeadingProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
};

export const Heading = ({ title, subtitle, children }: HeadingProps) => {
  return (
    <div className="p-9 flex flex-col border-b-2 w-full">
      <h1 className="text-3xl font-bold text-primary-title mb-6">{title}</h1>
      <span className="text-primary-subtitle">{!!subtitle && subtitle}</span>
      {children}
    </div>
  );
};
