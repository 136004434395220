import * as React from 'react';

export type NotificationPosition =
  | 'top-center'
  | 'top-right'
  | 'top-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'bottom-left';

export type NotificationType = 'success' | 'error' | 'warning' | 'info';

/**
 * Notification Type
 * @property {string} key - Unique key for the notification
 * @property {string} message - Message to display
 * @property {NotificationPosition} [position] - Position of the notification (top, bottom, left, right, center)
 * @property {NotificationType} [type] - Type of the notification (success, error, warning, info)
 * @property {boolean | number} [persist] - True to Persist the notification until close action or duration in milliseconds to close
 */
export type Notification = {
  key: string;
  message: string | React.ReactElement;
  position?: NotificationPosition;
  type?: NotificationType;
  persist?: boolean | number;
};

export type NotificationContextState = {
  notifications: Notification[];
  addNotification: (
    message: string | React.ReactElement,
    options?: Partial<Omit<Notification, 'message'>>,
  ) => void;
  removeNotification: (key: string) => void;
  info: (message: string | React.ReactElement) => void;
  success: (message: string | React.ReactElement) => void;
  warning: (message: string | React.ReactElement) => void;
  error: (message: string | React.ReactElement) => void;
};

const NotificationContext = React.createContext<
  NotificationContextState | undefined
>(undefined);

type NotificationProviderProps = {
  children: React.ReactNode;
};

const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  const info = (message: string | React.ReactElement) =>
    addNotification(message, { type: 'info', position: 'top-center' });

  const success = (message: string | React.ReactElement) =>
    addNotification(message, { type: 'success', position: 'top-center' });

  const warning = (message: string | React.ReactElement) =>
    addNotification(message, { type: 'warning', position: 'top-center' });

  const error = (message: string | React.ReactElement) =>
    addNotification(message, { type: 'error', position: 'top-center' });

  const addNotification = (
    message: string | React.ReactElement,
    options?: Partial<Omit<Notification, 'message'>>,
  ) => {
    const key = new Date().getTime().toString() + Math.random().toString();
    const notification = { message, key, ...options };

    setNotifications((prevNotifications) => [
      ...prevNotifications,
      notification,
    ]);

    const persistTime = handlePersist(notification.persist);

    if (persistTime) {
      setTimeout(() => {
        removeNotification(key);
      }, persistTime);
    }

    return key;
  };

  const removeNotification = (key: string) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n.key !== key),
    );
  };

  const calculateTopMargin = (index: number) => {
    const baseMargin = 16;
    const notificationHeight = 50;

    const topNotifications = notifications.filter(
      (n) => n.position?.startsWith('top') && notifications.indexOf(n) < index,
    );

    return topNotifications.length * (notificationHeight + baseMargin) + 50;
  };

  const calculateBottomMargin = (index: number) => {
    const baseMargin = 16;
    const notificationHeight = 50;

    const bottomNotifications = notifications.filter(
      (n) =>
        n.position?.startsWith('bottom') && notifications.indexOf(n) < index,
    );

    return bottomNotifications.length * (notificationHeight + baseMargin);
  };

  const handlePersist = (persist: boolean | number | undefined) => {
    if (persist === true) {
      return null;
    }

    if (typeof persist === 'number') {
      return persist;
    }

    return 5000; // Default timeout
  };

  const value = {
    notifications,
    addNotification,
    removeNotification,
    info,
    success,
    warning,
    error,
  };

  const getTypeClass = (type?: NotificationType) => {
    switch (type) {
      case 'success':
        return 'bg-green-500';
      case 'error':
        return 'bg-red-500';
      case 'warning':
        return 'bg-yellow-500';
      case 'info':
      default:
        return 'bg-blue-500';
    }
  };

  const getPositionClass = (position?: NotificationPosition) => {
    switch (position) {
      case 'top-right':
        return 'top-4 right-4';
      case 'top-left':
        return 'top-4 left-4';
      case 'top-center':
        return 'top-4 left-1/2 transform -translate-x-1/2';
      case 'bottom-right':
        return 'bottom-4 right-4';
      case 'bottom-left':
        return 'bottom-4 left-4';
      case 'bottom-center':
      default:
        return 'bottom-4 left-1/2 transform -translate-x-1/2';
    }
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}

      <div className="fixed z-[9999]">
        {notifications.map((notification, index) => (
          <div
            key={notification.key}
            className={`fixed p-4 text-white rounded shadow-md w-80 ${getTypeClass(
              notification.type,
            )} ${getPositionClass(notification.position)}`}
            style={{
              marginTop: notification.position?.startsWith('top')
                ? calculateTopMargin(index)
                : undefined,
              marginBottom: notification.position?.startsWith('bottom')
                ? calculateBottomMargin(index)
                : undefined,
            }}
          >
            <div className="flex justify-between">
              <span>{notification.message}</span>
              <button
                className="ml-4 text-white"
                onClick={() => removeNotification(notification.key)}
              >
                &times;
              </button>
            </div>
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

const useNotification = () => {
  const context = React.useContext(NotificationContext);

  if (context === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    );
  }

  return context;
};

export { NotificationProvider, useNotification };
