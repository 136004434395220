import clsx from 'clsx';

interface LoadingProps {
  size?: string;
}

export const Loading = ({ size = '16' }: LoadingProps) => {
  return (
    <div className="flex items-center justify-center">
      <div
        className={clsx(
          `w-${size}`,
          `h-${size}`,
          'border-4 border-control-plane-400 border-t-transparent rounded-full animate-spin',
        )}
      />
    </div>
  );
};
