import { Button } from './Button';

type PricingCardProp = {
  title: string;
  price: string;
  features: string[];
  onClick: () => void;
  disabled?: boolean;
  buttonLabel?: string;
  img: React.ReactNode;
};
export const PricingCard = (props: PricingCardProp) => {
  const { title, price, features, onClick, disabled, buttonLabel, img } = props;
  return (
    <div
      className={`w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 justify-between`}
    >
      <div className="flex flex-col justify-center items-center">
        {!!img && <div className="pt-8">{img}</div>}
        <h2 className="text-2xl font-bold text-center py-4">{title}</h2>
        <p className="text-center text-4xl font-bold">{price}</p>
        <div className="text-center font-medium">
          {features.map((feature, index) => (
            <p
              key={index}
              className={`py-2 border-b mx-8 ${index === 0 ? 'mt-8' : ''}`}
            >
              {feature}
            </p>
          ))}
        </div>
      </div>
      <Button className="mt-8 py-3" onClick={onClick} disabled={disabled}>
        {buttonLabel ? buttonLabel : 'Choose Plan'}
      </Button>
    </div>
  );
};
