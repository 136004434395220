import * as Sentry from '@sentry/browser';
import { NotificationContextState } from 'src/contexts/NotificationContext';

export const copyToClipboard = (
  text: string,
  notification: NotificationContextState,
  message = 'Copied to clipboard',
) => {
  if (!notification) {
    const errorMessage = 'Notification context is not provided';
    console.error(errorMessage);

    Sentry.captureException(new Error(errorMessage), {
      extra: {
        text,
        functionName: 'copyToClipboard',
      },
    });

    return;
  }

  navigator.clipboard.writeText(text);
  notification.success(message);
};
