import { User as FirebaseUser, getAuth } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import * as React from 'react';

type User =
  | (FirebaseUser & {
      role?: string;
      env?: string;
      organization?: { id: string; name: string };
      loading: boolean;
      user?: FirebaseUser;
    })
  | null;

type ContextState = { user: User };

interface FirebaseAuthProviderProps {
  children: React.ReactNode;
}

const FirebaseAuthContext = React.createContext<ContextState | undefined>(
  undefined,
);

const FirebaseAuthProvider: React.FC<FirebaseAuthProviderProps> = ({
  children,
}: FirebaseAuthProviderProps) => {
  const [user, setUser] = React.useState<User>(null);

  React.useEffect(() => {
    getAuth().onAuthStateChanged(async (user) => {
      if (!user || !user.emailVerified) {
        setUser(Object.assign({}, user, { loading: false }));
        return;
      }

      const decodedToken = jwtDecode<{ role: string | undefined }>(
        await user.getIdToken(),
      );

      setUser(
        Object.assign({}, user, {
          role: decodedToken.role,
          loading: false,
          user,
        }),
      );
    });
  }, []);

  return (
    <FirebaseAuthContext.Provider value={{ user }}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

function useFirebaseAuth() {
  const context = React.useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error(
      'useFirebaseAuth must be used within a FirebaseAuthProvider',
    );
  }

  return context.user;
}

export { FirebaseAuthProvider, useFirebaseAuth };
