import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { getFirstAndLastFourUUID } from 'src/utils/number';
import { Button } from './Button';
import { Loading } from './Loading';
import { Modal } from './Modal';
import { Option, SelectBox } from './SelectBox';

interface EnvironmentSwitcherModalProps {
  modalOpen: boolean;
  title?: string;
  setModalOpen?: (open: boolean) => void;
}

export const EnvironmentSwitcherModal = ({
  modalOpen,
  title = 'Switch environment',
  setModalOpen = () => {},
}: EnvironmentSwitcherModalProps) => {
  const auth = getAuth();
  const { environment, updateEnvironment } = useEnvironment();
  const [currentEnvironment, setCurrentEnvironment] = useState<string>(
    environment?.envId as string,
  );

  const confirmEnvironment = async () => {
    setModalOpen(false);
    updateEnvironment({
      id: auth.currentUser?.uid || '',
      envId: currentEnvironment,
    });
    window.location.reload();
  };

  return (
    <Modal
      title={title}
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      actionButton={<Button onClick={confirmEnvironment}>Confirm</Button>}
      className="w-96"
    >
      <div className="h-28">
        <EnvironmentSwitcher
          onChange={(value) => setCurrentEnvironment(value)}
          className="min-w-[140px] mt-10"
        />
      </div>
    </Modal>
  );
};

type EnvironmentSwitchProps = {
  onChange?: (value: string) => void;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default';
  className?: string;
};

export const EnvironmentSwitcher = ({
  onChange,
  color,
  className,
}: EnvironmentSwitchProps) => {
  const { environment, environmentList, fetchEnvironments } = useEnvironment();
  const environments: Option[] = environmentList.map((env) => ({
    value: env.id,
    label: `${env.name} (${getFirstAndLastFourUUID(env.id)})`,
  }));
  const [selectedEnvironment, setSelectedEnvironment] = useState<string>();
  const [key, setKey] = useState<number>(0);

  const handleChange = async (envId: string) => {
    setSelectedEnvironment(envId);
    onChange?.(envId);
  };

  useEffect(() => {
    fetchEnvironments();
  }, []);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [environmentList]);

  useEffect(() => {
    setSelectedEnvironment(environment?.envId);
  }, [environment, environments]);

  if (!environment || !environments.length || !selectedEnvironment) {
    return <Loading size={'8'} />;
  }

  return (
    <SelectBox
      options={environments}
      variant="outlined"
      color={color || 'primary'}
      size="medium"
      onChange={handleChange}
      defaultValue={environments.find(
        (env) => env.value === selectedEnvironment,
      )}
      className={className}
      key={`SelectBox--${environment?.envId}--${key}`}
    />
  );
};
