import {
  ArrowSquareOut,
  Copy,
  MagnifyingGlass,
  Pencil,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Heading } from 'src/components/Heading';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Prompt } from 'src/interfaces/prompt.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { limitString } from 'src/utils/limitString';

const headers = [
  { key: 'id', label: 'ID', width: '30%' },
  { key: 'content', label: 'Content', width: '30%' },
  { key: 'action', label: 'Action', width: '20%', disableSorting: true },
];

export const ListPrompts = () => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const navigate = useNavigate();

  const [rows, setRows] = useState<Prompt[]>([]);
  const [originalRows, setOriginalRows] = useState<Prompt[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/prompts?${query.toString()}`, {
      envId,
    });

    const prompts = data.items.map((prompt: Prompt) => ({
      id: prompt.id,
      content: limitString(prompt.content, 50),
      action: (
        <>
          <Tooltip content="Copy ID to clipboard" position="top">
            <Button
              variant="outlined"
              onClick={() =>
                copyToClipboard(
                  prompt.id,
                  notification,
                  'ID Copied to clipboard',
                )
              }
            >
              <Copy className="cursor-pointer inline-block w-5 h-5" />
            </Button>
          </Tooltip>

          <Button
            className="ml-2"
            onClick={() => navigate(`/prompt/edit/${prompt.id}`)}
          >
            <Pencil className="cursor-pointer inline-block w-5 h-5" />
          </Button>
        </>
      ),
    }));

    setRows(prompts as never[]);
    setOriginalRows(prompts);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, sortConfig]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: keyof Prompt, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    const filteredRows = originalRows.filter((row: Prompt) =>
      row.content?.toLowerCase().includes(filter.toLowerCase()),
    );

    setRows(filteredRows);
  };

  useEffect(() => {
    submitFilter();
  }, [debouncedFilter]);

  return (
    <div className="flex-1">
      <Heading
        title="Prompts"
        subtitle="Prompts outline how your agent should behave during a call"
      >
        <div className="flex">
          <Button
            className="mt-6 w-40"
            onClick={() => navigate('/prompt/create')}
          >
            Create Prompt
          </Button>

          <Button
            className="ml-4 mt-6 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/prompts"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-control-plane-400"
            required
            placeholder="Search prompts"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={submitFilter}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={rows}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={setSize}
          defaultSize={size}
        />
      </ContentWrapper>
    </div>
  );
};
