import clsx from 'clsx';
import { useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';

type EquivalentCodeProps = {
  payload?: unknown;
  endpoint: string;
  method: string;
  headers?: { [key: string]: string };
};

export const EquivalentCode = ({
  payload,
  endpoint,
  method,
  headers = {},
}: EquivalentCodeProps) => {
  const [activeTab, setActiveTab] = useState('curl');

  const requestUrl = process.env.REACT_APP_BASE_CORE_API_URL + endpoint;

  headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer <api_key>`,
    ...headers,
  };

  const codeExamples: { [key: string]: string } = {
    curl: `curl -X ${method} \\
    "${requestUrl}" \\
    ${
      headers
        ? Object.entries(headers)
            .map(([key, value]) => `  -H "${key}: ${value}" \\`)
            .join('\n')
        : ''
    }${
      payload && method !== 'GET'
        ? `\n  -d '${JSON.stringify(payload, null, 2)}'`
        : ''
    }`,

    python: `
import requests

url = "${requestUrl}"
headers = ${JSON.stringify(headers, null, 2)}
response = requests.${method.toLowerCase()}(url${payload && method !== 'GET' ? `, json=${JSON.stringify(payload, null, 2)}` : ''}, headers=headers)
print(response.json())
    `.trim(),

    javascript: `
fetch("${requestUrl}", {
  method: "${method}",
  headers: ${JSON.stringify(headers, null, 2)}${
    payload && method !== 'GET'
      ? `,\n  body: JSON.stringify(${JSON.stringify(payload, null, 2)})`
      : ''
  }
})
  .then(response => response.json())
  .then(data => console.log(data));
    `.trim(),

    php: `
<?php
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, "${requestUrl}");
curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "${method}");
${payload && method !== 'GET' ? `curl_setopt($ch, CURLOPT_POSTFIELDS, stripslashes(${JSON.stringify(JSON.stringify(payload))}));` : ''}
curl_setopt($ch, CURLOPT_HTTPHEADER, ${JSON.stringify(
      Object.entries(headers).map(([key, value]) => `${key}: ${value}`),
    )});
$response = curl_exec($ch);
curl_close($ch);
echo $response;
?>
    `.trim(),

    go: `
package main

import (
  "bytes"
  "net/http"
  "io/ioutil"
  "fmt"
)

func main() {
  url := "${requestUrl}"
  var jsonStr = []byte(\`${JSON.stringify(payload, null, 2)}\`)
  req, _ := http.NewRequest("${method}", url${payload && method !== 'GET' ? `, bytes.NewBuffer(jsonStr)` : ', nil'})
  req.Header.Set("Content-Type", "application/json")
  ${
    headers
      ? Object.entries(headers)
          .map(([key, value]) => `req.Header.Set("${key}", "${value}")`)
          .join('\n  ')
      : ''
  }
  client := &http.Client{}
  resp, _ := client.Do(req)
  defer resp.Body.Close()
  body, _ := ioutil.ReadAll(resp.Body)
  fmt.Println(string(body))
}
    `.trim(),

    java: `
import java.io.OutputStream;
import java.net.HttpURLConnection;
import java.net.URL;

public class Main {
    public static void main(String[] args) {
        try {
            URL url = new URL("${requestUrl}");
            HttpURLConnection conn = (HttpURLConnection) url.openConnection();
            conn.setRequestMethod("${method}");
            ${
              headers
                ? Object.entries(headers)
                    .map(
                      ([key, value]) =>
                        `conn.setRequestProperty("${key}", "${value}");`,
                    )
                    .join('\n    ')
                : ''
            }
            ${
              payload && method !== 'GET'
                ? `
            conn.setDoOutput(true);
            String jsonInputString = ${JSON.stringify(JSON.stringify(payload))};
            try (OutputStream os = conn.getOutputStream()) {
                byte[] input = jsonInputString.getBytes("utf-8");
                os.write(input, 0, input.length);
            }`
                : ''
            }
            int code = conn.getResponseCode();
            System.out.println("Response code: " + code);
        } catch (Exception e) {
            e.printStackTrace();
        }
    }
}
    `.trim(),
  };

  return (
    <div className="p-4 bg-gray-800 text-white rounded-md">
      <div className="flex space-x-4 border-b border-gray-700 overflow-auto scrollbar">
        {Object.keys(codeExamples).map((tab) => (
          <button
            key={tab}
            className={clsx('py-2 px-4 rounded-t-md text-sm font-medium', {
              'bg-gray-700 text-white': activeTab === tab,
              'text-gray-400': activeTab !== tab,
            })}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <CopyBlock
        key={activeTab}
        text={codeExamples[activeTab]}
        language={activeTab === 'curl' ? 'shell' : activeTab}
        theme={dracula}
        showLineNumbers={false}
        customStyle={{
          overflowX: 'auto',
          minHeight: '60px',
        }}
        wrapLongLines={true}
      />
    </div>
  );
};
