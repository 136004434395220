import { Navigate, Outlet } from 'react-router-dom';
import { UnloggedLayout } from '../UnloggedLayout';
import { useFirebaseAuth } from 'src/contexts/AuthContext/FirebaseAuthContext';
import { Loading } from '../Loading';

export const FirebaseUserGuard = () => {
  const user = useFirebaseAuth();

  if (!user || user.loading) {
    return (
      <UnloggedLayout>
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </UnloggedLayout>
    );
  }

  if (!user.uid) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
