import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get, patch, post } from 'src/api/requests';
import { StepManager } from 'src/components/StepManager';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Agent } from 'src/interfaces/agent.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { AgentStub } from 'src/stubs/agent.stub';
import { AgentSingleStep } from './components/AgentSingleStep';

type AgentProps = {
  editing?: boolean;
};

export const ManageAgent = ({ editing = false }: AgentProps) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const { id: editId } = useParams();
  const [agent, setAgent] = useState<Agent>(AgentStub as Agent);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [voices, setVoices] = useState<Voice[]>([]);
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const navigate = useNavigate();
  const notification = useNotification();

  const fetchVoices = async () => {
    const { items } = await get('/voices?size=30', { envId });
    setVoices(items);
  };

  const fetchPrompts = async () => {
    const { items } = await get('/prompts?size=30', { envId });
    setPrompts(items);
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);

      await Promise.all([fetchVoices(), fetchPrompts()]);

      setLoading(false);
    };

    loadInitialData();
  }, [envId]);

  const saveAgent = async () => {
    setLoading(true);

    const url = editing ? `/agents/${editId}` : '/agents';

    if (editing) {
      await patch(url, agent, { envId });
    } else {
      await post(url, agent, { envId });
    }

    notification.success(
      `Agent ${editing ? 'updated' : 'created'} successfully`,
    );
    navigate('/agents');
    setLoading(false);
  };

  useEffect(() => {
    if (editId) {
      get(`/agents/${editId}`, { envId }).then((data) => {
        setAgent({
          ...data,
          llm_fallback: data.llm_fallback || AgentStub.llm_fallback,
          prompt: data.prompt?.id || '',
          voice: data.voice?.id || '',
        });
      });
    }
  }, [editId, envId]);

  useEffect(() => {
    setDisabled(!agent.voice || !agent.prompt);
  }, [agent]);

  const stepsContent = [
    {
      title: 'Configure Your Agent',
      component: (
        <AgentSingleStep
          agent={agent}
          setAgent={setAgent}
          loading={loading}
          voices={voices}
          prompts={prompts}
        />
      ),
    },
  ];

  return (
    <>
      <StepManager
        title={editing ? 'Edit Agent' : 'New Agent'}
        stepsContent={stepsContent}
        finishAction={disabled ? undefined : saveAgent}
        loading={loading}
      />
    </>
  );
};
